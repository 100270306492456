import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { Link } from 'src/components/core/external-link';

import '../style.scss';

import { default as ComponentData } from '../../../../../../data/components/menus/wealth-management';
const wmProducts = require('../../../../../../data/content-types/wealth-management-products/en.json');
const esWmProducts = require('../../../../../../data/content-types/wealth-management-products/es.json');

const WealthManagementMenu = () => {
  const { language } = useI18next();
  const data = ComponentData[language];
  const products = language === 'en' ? wmProducts : esWmProducts;

  return (
    <div className="mobile-sub-menu">
      <div className="category-menu">
        <h6>{data.menuTitle}</h6>
        <ul>
          <li>
            <Link to="/wealth-management">Wealth Management Overview</Link>
          </li>
          {products.map(product => (
            <li key={product.slug}>
              <Link to={`/wealth-management/${product.slug}`}>
                {product.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WealthManagementMenu;

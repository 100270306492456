import React, { useState } from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { PersonalIcon, BusinessIcon } from 'src/components/icons/navigation';
import { default as IconLink } from './icon-link';
import { navigate } from 'gatsby';
import { Link } from 'src/components/core/external-link';

import { NavSearchIcon, NavLockIcon, NavArrowIcon } from 'src/components/icons';
import { default as BankingMenu } from './menus/banking';
import { default as MortgageMenu } from './menus/mortgage';
import { default as InsuranceMenu } from './menus/insurance';
import { default as DigitalBankingMenu } from './menus/digital-banking';
import { default as WealthManagementMenu } from './menus/wealth-management';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import './style.scss';

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderTop: `1px solid #d3d8de`,
  // borderBottom: `1px solid #6A646D`,
  // "&:first-child": {
  //   borderTop: 0,
  // },
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  flexDirection: 'row',
  padding: theme.spacing(0.75),
  paddingRight: theme.spacing(3),
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },

  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2.5),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  paddingLeft: theme.spacing(3.5),
  paddingRight: theme.spacing(3.5),
}));

export default () => {
  const { language } = useI18next();
  const { t } = useTranslation('TopBar');

  const [expanded, setExpanded] = useState();
  const handleChange = panel => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ height: '88%', overflowY: 'auto', paddingBottom: 16 }}>
      <div
        className="accordion__itemButton"
        style={{ backgroundColor: '#f5f5f5' }}
      >
        <div>
          <button onClick={() => navigate('/sign-in')} tabIndex="-1">
            <NavLockIcon />
            <h3 className="accordion__itemTitle">{t('login')}</h3>
            <div className="accordion__itemIconWrap">
              <NavArrowIcon />
            </div>
          </button>
        </div>
      </div>

      <div className="accordion__staticItem">
        <div className="accordion__itemTitleWrap">
          <Link to="/search" tabIndex="-1">
            <h3 className="accordion__itemTitle">{t('search')}</h3>
            <div className="accordion__itemIconWrap">
              <NavSearchIcon />
            </div>
          </Link>
        </div>
      </div>

      <div className="accordion__staticItem">
        <div className="accordion__itemTitleWrap">
          <Link
            to="/banking/personal/lsnb-credit-cards"
            style={{ borderTop: '1px solid #d3d8de' }}
            tabIndex="-1"
          >
            <h3 className="accordion__itemTitle">{t('credit_cards')}</h3>
            <div className="accordion__itemIconWrap"></div>
          </Link>
        </div>
      </div>

      {/* Banking */}
      <Accordion
        expanded={expanded === 'banking'}
        onChange={handleChange('banking')}
      >
        <AccordionSummary>
          <h3 className="accordion__itemTitle">{t('banking')}</h3>
        </AccordionSummary>
        <AccordionDetails>
          <BankingMenu />
        </AccordionDetails>
      </Accordion>

      {/* Wealth Management */}
      <Accordion
        expanded={expanded === 'wealth_management'}
        onChange={handleChange('wealth_management')}
      >
        <AccordionSummary>
          <h3 className="accordion__itemTitle">{t('wealth_management')}</h3>
        </AccordionSummary>
        <AccordionDetails>
          <WealthManagementMenu />
        </AccordionDetails>
      </Accordion>

      <div className="accordion__staticItem">
        <div className="accordion__itemTitleWrap">
          <Link
            to="/investments"
            style={{ borderTop: '1px solid #d3d8de' }}
            tabIndex="-1"
          >
            <h3 className="accordion__itemTitle">{t('investments')}</h3>
            <div className="accordion__itemIconWrap"></div>
          </Link>
        </div>
      </div>

      {/* Insurance */}
      <Accordion
        expanded={expanded === 'insurance'}
        onChange={handleChange('insurance')}
      >
        <AccordionSummary>
          <h3 className="accordion__itemTitle">{t('insurance')}</h3>
        </AccordionSummary>
        <AccordionDetails>
          <InsuranceMenu />
        </AccordionDetails>
      </Accordion>

      {/* Digital Banking */}
      <Accordion
        expanded={expanded === 'digital_banking'}
        onChange={handleChange('digital_banking')}
      >
        <AccordionSummary>
          <h3 className="accordion__itemTitle">{t('digital_banking')}</h3>
        </AccordionSummary>
        <AccordionDetails>
          <DigitalBankingMenu />
        </AccordionDetails>
      </Accordion>

      {/* Mortgage */}
      <Accordion
        expanded={expanded === 'mortgage'}
        onChange={handleChange('mortgage')}
      >
        <AccordionSummary>
          <h3 className="accordion__itemTitle">{t('mortgage')}</h3>
        </AccordionSummary>
        <AccordionDetails>
          <MortgageMenu />
        </AccordionDetails>
      </Accordion>

      <div className="accordion__staticItem">
        <div className="accordion__itemTitleWrap">
          <Link
            to="/locations"
            style={{ borderTop: '1px solid #d3d8de' }}
            tabIndex="-1"
          >
            <h3 className="accordion__itemTitle">{t('find_atm')}</h3>
            <div className="accordion__itemIconWrap"></div>
          </Link>
        </div>
      </div>
    </div>
  );
};

import Geocode from "react-geocode"


async function geoLocate(addr) {
  if (addr === undefined || addr === null || addr === "")
    throw "A valid address is required!"

  Geocode.setApiKey("AIzaSyCXg-duFlo7ekB-K_WS3x3tJAUO9_UIICo")
  Geocode.setLocationType("APPROXIMATE")

  let result = {}
  await Geocode.fromAddress(addr).then(res => {
    const { lat, lng } = res.results[0].geometry.location 
    if (!isNaN(lat) && !isNaN(lng)) {
      result.lat = lat
      result.lng = lng 
    }
  })

  return result 
}


export default geoLocate
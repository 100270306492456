import React, { useEffect, useRef } from "react"
import { TimelineMax, TweenMax } from "gsap/all"
import { newlineToBr, asArray } from "src/util"
import { Button } from "src/components/core"
import { XIcon } from "src/components/icons"

import "./style.scss"


import Lines from "src/assets/images/banking-business/lines.png"
import gsap from "gsap/gsap-core"


export default ({ data, onClose }) => {
  const elem = useRef(null)
  const tween = useRef(null)

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("no-scroll")
    return () => document.getElementsByTagName("body")[0].classList.remove("no-scroll")
  })

  useEffect(() => {
    const g1 = asArray(elem.current.querySelectorAll(".shadow-image"))
    const g2 = asArray(elem.current.querySelectorAll("h3, h4, a, p, .button"))
    gsap.set(g1, { autoAlpha: 0, x: -30 })
    gsap.set(g2, { autoAlpha: 0, y: 30 })


    tween.current = new TimelineMax()
    tween.current 
      .add(TweenMax.to(elem.current, 0.25, { scale: 1, opacity: 1 })) 
      .add(TweenMax.to(g1, 0.5, { autoAlpha: 1, x: 0 }))
      .add(TweenMax.to(g2, 0.5, { autoAlpha: 1, y: 0, stagger: 0.125 }), "-=0.25")
      .play()

    return () => tween.current.kill()
  })

  return (
    
    <div className="team-member-modal" ref={elem}>
      <button className="close-button" onClick={onClose}><XIcon /></button>
      <div className="modal-content">
        <div className="image-content">
          <div className="shadow-image">
            <img className="lines" src={Lines} alt="Lines Accent" />

            <div className="image-wrap">
              <img src={data.image.src} alt={data.name} />
            </div>
          </div>
        </div>
        <div className="text-content">
          <div className="title-wrap">
            <h3>{data.name}</h3>
            <h4>{data.title}</h4>
            
            {(data.mortgage !== undefined && data.mortgage !== null) && (
              <div className="mortgage-details">
                <p>
                  NMLS# {data.mortgage.nmls}
                </p>
                <Button to={data.mortgage.apply.url} openInNewWindow={true}>{data.mortgage.apply.title}</Button>
              </div>
            )}
            {(data.phone !== "" && data.phone !== undefined) && (
              <a href={`tel:${data.phone}`} target="_blank">{data.phone}</a>
            )}  
          </div>
          
          <div className="bio-wrap">
            <p dangerouslySetInnerHTML={{__html: newlineToBr(data.bio)}}></p>
          </div>
        </div>
      </div>
    </div>
  )
}
import React, { useRef, useEffect } from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import gsap, { TimelineMax, TweenMax } from 'gsap/all';

import { Title, Preface } from 'src/components/core';
// import { Link } from "gatsby"
import { Link } from 'src/components/core/external-link';

import './style.scss';
import '../style.scss';

import { default as ComponentData } from '../../../../../../data/components/menus/digital-banking';
const mortgageProducts = require('../../../../../../data/content-types/digital-banking-products/en.json');
const esMortgageProducts = require('../../../../../../data/content-types/digital-banking-products/es.json');

const whitelist = [
  'person-to-person-transfers',
  'e-statements',
  'bank-to-bank-transfers',
  'atm-services',
  'bill-pay',
  'sms-banking',
  'card-manager',
  'contactless-transactions',
];

export default ({ isOpen, closeActiveMenu }) => {
  const { language } = useI18next();
  const { t } = useTranslation('TopBar');
  const data = ComponentData[language];
  const products = language === 'en' ? mortgageProducts : esMortgageProducts;

  const minHeight = 388;
  const menu = useRef(null);
  const title = useRef(null);
  const content = useRef(null);
  const personal = useRef(null);
  const openTween = useRef(null);

  useEffect(() => {
    // Set default values
    gsap.set(menu.current, {
      height: Math.max(personal.current.offsetHeight + 100, minHeight),
    });
    gsap.set(menu.current, { autoAlpha: 0, y: -300 });
    gsap.set(content.current, { autoAlpha: 0 });

    // Build reveal animation
    openTween.current = new TimelineMax();
    openTween.current
      .delay(0.25)
      .add(gsap.set(personal.current, { autoAlpha: 1 }))
      .add(TweenMax.to(menu.current, 0.25, { autoAlpha: 1, y: 0 }))
      .add(TweenMax.to(content.current, 0.3, { autoAlpha: 1 }));

    openTween.current.play().reverse();

    return () => {
      openTween.current.kill();
    };
  }, []);

  useEffect(() => {
    openTween.current.reversed(!isOpen);
  }, [isOpen]);

  const mouseLeave = () => {
    closeActiveMenu();
  };

  return (
    <nav className="top-bar-menu mortgage" ref={menu} onMouseLeave={mouseLeave}>
      <div className="content-wrap" ref={content}>
        <div className="title-section" ref={title}>
          <Title>{data.title}</Title>
          <Preface>{data.subTitle}</Preface>
        </div>
        <div className="menu-section">
          <div className="menu personal-menu active" ref={personal}>
            <div className="category-menu">
              <h6>{data.menuTitle}</h6>
              <ul>
                {products
                  .filter(x => {
                    return whitelist.indexOf(x.slug) !== -1;
                  })
                  .map(product => (
                    <li key={product.slug}>
                      <Link to={`/digital-banking/${product.slug}`}>
                        {product.title}
                      </Link>
                    </li>
                  ))}
                <li key="zelle">
                  <Link to={`/digital-banking/zelle`}>Zelle®</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

import React from "react"
import { ReactComponent as YoutubeIcon } from "src/assets/svg/footer/youtube-icon.svg"
import "./style.scss"

export default () => {
  return (
    <div className="footer-youtube-icon">
      <YoutubeIcon />
    </div>
  )
}

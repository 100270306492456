import React from "react"
import InvestmentsImg from "src/assets/images/home/investments-icon.png"
import "./style.scss"

export default () => {
  return (
    <div className="home-investments-icon">
      <img src={InvestmentsImg} alt="Investments" />
    </div>
  )
}
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'src/components/core/external-link';
import { navigate } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
// import { useCookies } from "react-cookie"
import gsap, { TweenMax, ScrollToPlugin, Power4 } from 'gsap/all';

import { WarningIcon } from 'src/components/icons';

import {
  Accordion,
  AccordionItem,
  Button,
  SignInModal,
  Notification,
} from 'src/components/core';
import {
  NavSearchIcon,
  NavLockIcon,
  NavArrowIcon,
  XIcon,
} from 'src/components/icons';
import { default as MobileNav } from 'src/components/core/nav-mobile';

import { slide as Menu } from 'react-burger-menu';
import { default as Nav } from 'src/components/core/nav';
import { default as BurgerMenuStyles } from '../nav-mobile/burger';
import { default as HolidayModal } from '../holiday-hours';

import { AnimationContext } from 'src/context';

import { default as Roadbump } from '../roadbump';
import { PhishingModal } from 'src/components/core';

import moment from 'moment';
// import AnimationContext from "src/context/animationContext"

import './style.scss';

gsap.registerPlugin(ScrollToPlugin);

// const bankingProductData = {
//   en: require("../../../../data/content-types/banking-products/en.json"),
//   es: require("../../../../data/content-types/banking-products/en.json")
// }

// const insuranceProductData = {
//   en: require("../../../../data/content-types/insurance-products/en.json"),
//   es: require("../../../../data/content-types/insurance-products/es.json")
// }

const notification =
  'The Annual Shareholder Meeting will be held in-person at the Corporate Office in McAllen, TX at 9:00AM on April 26, 2022.';
const evalCookie = (cookieProperty, expectedValue, result, defaultValue) => {
  if (cookieProperty === null || cookieProperty === undefined)
    return defaultValue;

  if (cookieProperty === expectedValue) return result;

  return defaultValue;
};

export default ({ children, useLightNav, forceNavVisible, whiteNav }) => {
  const accordionRef = useRef(null);
  const main = useRef(null);
  const scrollTween = useRef(null);
  // const { language } = useI18next()
  const { t } = useTranslation('TopBar');

  // Menu Vars
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSignInOpen, setSignInOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  // Road Bump Vars
  const [isBumpOpen, setBumpOpen] = useState(false);
  const [bumpUrl, setBumpUrl] = useState(undefined);
  const [showExtended, setShowExtended] = useState(false);

  const [holidayHoursOpen, setHolidayHoursOpen] = useState(false);

  // Preloader vars
  // const [cookies, setCookie] = useCookies(['lsnb']);
  // let srcAnimDelay = 150,
  //   srcShowPreloader = false;
  // if (cookies.showPreloader === null || cookies.showPreloader === undefined) {
  //   srcAnimDelay = 2100;
  //   srcShowPreloader = true;
  //   setCookie('showPreloader', 'false', {
  //     expires: moment().add(2, 'hours').toDate(),
  //   });
  // }
  const [animDelay] = useState(150);
  // let srcShowNotification = true;
  // if (
  //   cookies.showNotification !== null ||
  //   cookies.showNotification !== undefined
  // ) {
  //   srcShowNotification = cookies.showNotification === 'false' ? false : true;
  // }
  const [showNotification, setShowNotification] = useState(false);
  const [showPhishingModal, setShowPhishingModal] = useState(false);

  const didUpdate = () => {
    if (accordionRef.current) {
      if (isMenuOpen) {
        setTimeout(() => {
          TweenMax.to(accordionRef.current, 0.25, { opacity: 1 });
        }, 350);
      } else {
        TweenMax.to(accordionRef.current, 0.25, { opacity: 0 });
      }
    }
  };

  useEffect(() => {
    if (main.current && main.current.classList !== null) {
      main.current.classList.add('ready');
    }
  }, [animDelay, showNotification]);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash !== undefined && hash !== null && hash !== '') {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1);
      setTimeout(() => {
        let target = hash.replace('#!', '');
        const el = document.getElementById(target);

        if (el !== null) {
          const nav = document.getElementsByClassName('primary-navigation')[0];
          const siteNotification = document.getElementsByClassName(
            'site-notification'
          )[0];
          let offset = el.offsetTop - nav.offsetHeight;
          if (siteNotification !== undefined && siteNotification !== null) {
            offset -= siteNotification.offsetHeight;
          }
          scrollTween.current = gsap.to(window, {
            duration: 1.5,
            scrollTo: offset,
            ease: Power4.easeInOut,
          });
        }
      }, animDelay + 750);
    }

    return () => {
      if (scrollTween.current) scrollTween.current.kill();
    };
  }, [animDelay]);

  return (
    <AnimationContext.Provider
      value={{
        animDelay: animDelay,
        isOpen: isBumpOpen,
        setIsOpen: setBumpOpen,
        url: bumpUrl,
        setUrl: setBumpUrl,
        showExtended,
        setShowExtended,
      }}
    >
      <HolidayModal
        isOpen={holidayHoursOpen}
        setIsOpen={setHolidayHoursOpen}
        url="https://google.com"
        showExtended={false}
      />
      {showPhishingModal && (
        <PhishingModal onClose={() => setShowPhishingModal(false)} />
      )}

      <main
        className={`main ${showNotification ? 'show-notification' : ''}`}
        role="main"
        ref={main}
      >
        <div id="outer-wrap">
          {showNotification && (
            <Notification setShow={setShowNotification}>
              <span style={{ marginRight: 8 }}>
                <WarningIcon />
              </span>
              Protect yourself from fraudulent calls.{' '}
              <button
                onClick={() => setShowPhishingModal(true)}
                style={{ textTransform: 'none' }}
              >
                Click here
              </button>{' '}
              for some helpful tips.
              <button
                style={{
                  border: 'none',
                  position: 'absolute',
                  top: 5,
                  right: 15,
                }}
                onClick={e => {
                  e.preventDefault();
                  setShowNotification(false);
                }}
              >
                <XIcon />
              </button>
            </Notification>
          )}

          {/* <Menu
            width={325}
            pageWrapId={'page-wrap'}
            outerContainerId={'outer-wrap'}
            onStateChange={didUpdate}
            isOpen={isMenuOpen}
            onClose={() => setMenuOpen(false)}
            styles={BurgerMenuStyles}
            right
          >
            <div
              style={{
                opacity: 0,
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
              }}
              ref={accordionRef}
            >
              <MobileNav /> */}
          {/* <div
                style={{
                  flexGrow: 1,
                  overflow: 'auto',
                  position: 'relative',
                }}
              >
                <Accordion>
                  <div
                    className="accordion__itemButton"
                    style={{ backgroundColor: '#f5f5f5' }}
                  >
                    <div>
                      <button
                        onClick={() => navigate('/sign-in')}
                        tabIndex="-1"
                      >
                        <NavLockIcon />
                        <h3 className="accordion__itemTitle">{t('login')}</h3>
                        <div className="accordion__itemIconWrap">
                          <NavArrowIcon />
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="accordion__staticItem">
                    <div className="accordion__itemTitleWrap">
                      <Link to="/search" tabIndex="-1">
                        <h3 className="accordion__itemTitle">{t('search')}</h3>
                        <div className="accordion__itemIconWrap">
                          <NavSearchIcon />
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="accordion__staticItem">
                    <div className="accordion__itemTitleWrap">
                      <Link
                        to="/banking"
                        style={{ borderTop: '1px solid #d3d8de' }}
                        tabIndex="-1"
                      >
                        <h3 className="accordion__itemTitle">{t('banking')}</h3>
                        <div className="accordion__itemIconWrap"></div>
                      </Link>
                    </div>
                  </div>

                  <div className="accordion__staticItem">
                    <div className="accordion__itemTitleWrap">
                      <Link
                        to="/banking/personal/lsnb-credit-cards"
                        style={{ borderTop: '1px solid #d3d8de' }}
                        tabIndex="-1"
                      >
                        <h3 className="accordion__itemTitle">
                          {t('credit_cards')}
                        </h3>
                        <div className="accordion__itemIconWrap"></div>
                      </Link>
                    </div>
                  </div>

                  <div className="accordion__staticItem">
                    <div className="accordion__itemTitleWrap">
                      <Link
                        to="/wealth-management"
                        style={{ borderTop: '1px solid #d3d8de' }}
                        tabIndex="-1"
                      >
                        <h3 className="accordion__itemTitle">
                          {t('wealth_management')}
                        </h3>
                        <div className="accordion__itemIconWrap"></div>
                      </Link>
                    </div>
                  </div>

                  <div className="accordion__staticItem">
                    <div className="accordion__itemTitleWrap">
                      <Link
                        to="/investments"
                        style={{ borderTop: '1px solid #d3d8de' }}
                        tabIndex="-1"
                      >
                        <h3 className="accordion__itemTitle">
                          {t('investments')}
                        </h3>
                        <div className="accordion__itemIconWrap"></div>
                      </Link>
                    </div>
                  </div>

                  <div className="accordion__staticItem">
                    <div className="accordion__itemTitleWrap">
                      <Link
                        to="/insurance"
                        style={{ borderTop: '1px solid #d3d8de' }}
                        tabIndex="-1"
                      >
                        <h3 className="accordion__itemTitle">
                          {t('insurance')}
                        </h3>
                        <div className="accordion__itemIconWrap"></div>
                      </Link>
                    </div>
                  </div>

                  <div className="accordion__staticItem">
                    <div className="accordion__itemTitleWrap">
                      <Link
                        to="/banking/digital"
                        style={{ borderTop: '1px solid #d3d8de' }}
                        tabIndex="-1"
                      >
                        <h3 className="accordion__itemTitle">
                          {t('digital_banking')}
                        </h3>
                        <div className="accordion__itemIconWrap"></div>
                      </Link>
                    </div>
                  </div>

                  <div className="accordion__staticItem">
                    <div className="accordion__itemTitleWrap">
                      <Link
                        to="/mortgage"
                        style={{ borderTop: '1px solid #d3d8de' }}
                        tabIndex="-1"
                      >
                        <h3 className="accordion__itemTitle">
                          {t('mortgage')}
                        </h3>
                        <div className="accordion__itemIconWrap"></div>
                      </Link>
                    </div>
                  </div>

                  <div className="accordion__staticItem">
                    <div className="accordion__itemTitleWrap">
                      <Link
                        to="/locations"
                        style={{ borderTop: '1px solid #d3d8de' }}
                        tabIndex="-1"
                      >
                        <h3 className="accordion__itemTitle">
                          {t('find_atm')}
                        </h3>
                        <div className="accordion__itemIconWrap"></div>
                      </Link>
                    </div>
                  </div>
                </Accordion>
              </div> */}
          {/* </div>
          </Menu> */}
          {/* <Nav
            animDelay={animDelay}
            additionalNavClasses={`${useLightNav ? 'light' : ''} ${
              whiteNav ? 'white-nav' : ''
            } ${forceNavVisible ? 'force-visible' : ''}`}
            isMenuOpen={isMenuOpen}
            setMenuOpen={setMenuOpen}
          /> */}
          <div id="page-wrap">
            {/* <SignInModal visible={isSignInOpen} setVisible={setSignInOpen} /> */}
            <AnimationContext.Consumer>
              {({ isOpen, setIsOpen, url, showExtended }) => (
                <Roadbump
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  url={url}
                  showExtended={showExtended}
                />
              )}
            </AnimationContext.Consumer>

            {children}
          </div>
        </div>
      </main>
    </AnimationContext.Provider>
  );
};

import React from "react"
import InsuranceIcon from "src/assets/images/home/insurance-icon.png"
import "./style.scss"

export default () => {
  
  return (
    <div className="home-insurance-icon">
      <img src={InsuranceIcon} alt="Insurance" />
    </div>
  )
}
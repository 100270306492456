import React, { useState, useRef, useEffect } from "react"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import gsap, { TimelineMax, TweenMax } from "gsap/all"

import { Title } from "src/components/core"
import { IconUnderlineButton } from "src/components/core/buttons"
import { BusinessIcon, PersonalIcon } from "src/components/icons/navigation"
// import { Link } from "gatsby"
import { Link } from "src/components/core/external-link"
import { default as ComponentData } from "../../../../../../data/components/menus/insurance"

import "./style.scss"
import "../style.scss"

const insuranceProducts = require("../../../../../../data/content-types/insurance-products/en.json")
const esInsuranceProducts = require("../../../../../../data/content-types/insurance-products/es.json")


export default ({ isOpen, closeActiveMenu }) => {
  const { language } = useI18next()
  const data = ComponentData[language]
  const { t } = useTranslation("TopBar")
  const products = (language === "en") ? insuranceProducts : esInsuranceProducts

  const minHeight = 388
  const menu = useRef(null)
  const title = useRef(null)
  const content = useRef(null)
  const personalRef = useRef(null)
  const businessRef = useRef(null)
  const openTween = useRef(null)
  const swapTween = useRef(null)
  const [personal, setPersonal] = useState(true)
  const [business, setBusiness] = useState(false)

  
  useEffect(() => {
    
    // Set default values 
    gsap.set(menu.current, { height: Math.max((personalRef.current.offsetHeight + 100), minHeight) })  
    gsap.set(menu.current, { autoAlpha: 0, y: -300 })
    gsap.set(content.current, { autoAlpha: 0 })
    
    // Build reveal animation 
    openTween.current = new TimelineMax()
    openTween.current
      .delay(0.25)
      .add(gsap.set(personalRef.current, { autoAlpha: 1 }))
      .add(TweenMax.to(menu.current, 0.25, { autoAlpha: 1, y: 0 }))
      .add(TweenMax.to(content.current, 0.3, { autoAlpha: 1 }))
  

    openTween.current.play().reverse()

    return () => {
      openTween.current.kill()
    }
  }, [])

  useEffect(() => {
    openTween.current.reversed(!isOpen)
  }, [isOpen])


  
  const businessClick = () => {
    if (swapTween.current) swapTween.current.kill()   
    let targetHeight = Math.max((businessRef.current.offsetHeight + 100), minHeight)

    swapTween.current = new TimelineMax()
    swapTween.current
      .add(() => setPersonal(false))
      .add(() => setBusiness(true))
      .add(TweenMax.to(personalRef.current, 0.5, { autoAlpha: 0 }))
      
    if (menu.current.offsetHeight != targetHeight) 
      swapTween.current.add(TweenMax.to(menu.current, 0.5, { height: targetHeight }), "-=0.15")

    swapTween.current 
      .add(TweenMax.to(businessRef.current, 0.5, { autoAlpha: 1 }), "-=0.25")
      .play()
  }
  const pbClick = () => {
    if (swapTween.current) swapTween.current.kill() 
    let targetHeight = Math.max((personalRef.current.offsetHeight + 100), minHeight)

    swapTween.current = new TimelineMax()
    swapTween.current
      .add(() => setBusiness(false))
      .add(() => setPersonal(true))
      .add(TweenMax.to(businessRef.current, 0.5, { autoAlpha: 0 }))

    if (menu.current.offsetHeight != targetHeight) 
      swapTween.current.add(TweenMax.to(menu.current, 0.5, { height: targetHeight }), "-=0.15")

    swapTween.current
      .add(TweenMax.to(personalRef.current, 0.5, { autoAlpha: 1 }), "-=0.25")
      .play()
  }

  const mouseLeave = () => {
    pbClick()
    closeActiveMenu()
  }


  return (
    <nav className="top-bar-menu insurance" ref={menu} onMouseLeave={mouseLeave}>
      <div className="content-wrap" ref={content}>
        <div className="title-section" ref={title}>
          <Title>{t("insurance")}</Title>
          <IconUnderlineButton to="/insurance/personal" icon={<PersonalIcon />} label={t("personal")} active={personal} onMouseEnter={pbClick} />
          <IconUnderlineButton to="/insurance/business" icon={<BusinessIcon />} label={t("business")} active={business} onMouseEnter={businessClick} />
        </div>
        <div className="menu-section">
          <div className={`menu personal-menu${personal ? " active" : ""}`} ref={personalRef}>
            <div className="category-menu">
              <h6>{data.personalSolutions}</h6>
              <ul>
                {products.filter(product => {
                  return product.category === "personal"
                }).map(product => (
                  <li key={product.slug}>
                    <Link to={`/insurance/personal/${product.slug}`}>{product.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={`menu business-menu${business ? " active" : ""}`} ref={businessRef}>
            <div className="category-menu">
              <h6>{data.businessSolutions}</h6>
              <ul>
                {products.filter(product => {
                  return product.category === "business"
                }).map(product => (
                  <li key={product.slug}>
                    <Link to={`/insurance/business/${product.slug}`}>{product.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

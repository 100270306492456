import React from "react"
import { ReactComponent as LeftArrow } from "src/assets/svg/slider/left-icon.svg"

import "./style.scss"

export default () => (
  <div className="left-circle-arrow-icon">
    <LeftArrow />
  </div>
)

/* eslint-disable */

import React, { useRef, useEffect, useState } from "react"
import gsap, { ScrollTrigger } from "gsap/all"

import "./style.scss"

gsap.registerPlugin(ScrollTrigger)

export default ({
  title,
  poster,
  src,
  autoPlay,
  muted,
  loop,
  controls,
  useScrollTrigger
}) => {
  const [videoFormat] = useState("video/mp4")
  const [optimizedVideoSrc] = useState(src)
  const [visible, setVisible] = useState(false)
  const [wrapTrigger, setWrapTrigger] = useState(null)
  const wrapperRef = useRef(null)

  useEffect(() => {
    let wrapper = null

    if (wrapperRef.current) {
      wrapper = wrapperRef.current

      if (useScrollTrigger) {
        if (!wrapTrigger) {
          setWrapTrigger(ScrollTrigger.create({
            trigger: wrapper,
            onToggle: self => setVisible(self.isActive),
            onUpdate: self => console.log("Updated: ", self.isActive)
          }))
        }
      } else {
        setVisible(true)
      }
    }

    return () => {
      if (useScrollTrigger && wrapTrigger) wrapTrigger.kill()
    }
  }) // eslint-disable-line react-hooks/exhaustive-deps


  const VideoComponent = () => (
    <>
      <video
        className="video"
        poster={poster}
        controls={controls}
        autoPlay={autoPlay}
        loop={loop}
        muted={muted}
        playsInline
      >
        <source src={optimizedVideoSrc} type={videoFormat} />
      </video>
    </>
  )

  const PlaceholderComponent = () => (
    <div
      className="video-placeholder"
      aria-label={title}
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${poster})`,
        backgroundSize: "cover",
        backgroundPosition: "center"
      }}>
    </div>
  );

  return (
    <div className="video-wrapper" style={{ width: "100%", height: "100%" }} ref={wrapperRef}>
      {visible
        ? <VideoComponent />
        : <PlaceholderComponent poster={poster} title={title} />
      }
    </div>
  )
}

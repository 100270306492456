import React from "react"
import ArrowImg from "src/assets/images/home/little-arrow-active.png"
import { ReactComponent as BlueArrow } from "src/assets/svg/blue-arrow-icon.svg"

import "./style.scss"

export default () => (
  <div className="blue-arrow-icon">
    <img src={ArrowImg} alt="Blue Arrow" />
  </div>
)

import React from 'react';
import './style.scss';

const Notification = ({ children, setShow }) => {
  return (
    <div className="site-notification">
      <div className="content-wrap">
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default Notification;

import React from "react"
import { ReactComponent as InstagramIcon } from "src/assets/svg/footer/instagram-icon.svg"
import "./style.scss"

export default () => {
  return (
    <div className="footer-instagram-icon">
      <InstagramIcon />
    </div>
  )
}

import React from 'react';
import PhishingImg from 'src/assets/images/phishing.jpg';

import './style.scss';

export default ({ onClose }) => {
  return (
    <div className="disclosure-modal-phishing open">
      <div className="darkener" onClick={onClose}></div>
      <div className="content">
        <div className="body">
          <img style={{ width: '100%' }} src={PhishingImg} alt="Phishing" />
          <div className="actions">
            <button className="button hollow-button" onClick={onClose}>
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

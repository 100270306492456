import React, { useState, useRef, useEffect } from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import gsap, { TimelineMax, TweenMax } from 'gsap/all';

import { Title } from 'src/components/core';
import { IconUnderlineButton } from 'src/components/core/buttons';
import { BusinessIcon, PersonalIcon } from 'src/components/icons/navigation';
// import { Link } from "gatsby"
import { Link } from 'src/components/core/external-link';
import { default as ComponentData } from '../../../../../../data/components/menus/banking';

import './style.scss';
import '../style.scss';

const bankingProducts = require('../../../../../../data/content-types/banking-products/en.json');
const esBankingProducts = require('../../../../../../data/content-types/banking-products/es.json');
let categories = bankingProducts.map(product =>
  product.category.replace('business-', '').replace('personal-', '')
);
categories = [...new Set(categories)];

export default ({ isOpen, closeActiveMenu }) => {
  const { language } = useI18next();
  const data = ComponentData[language];
  const { t } = useTranslation('TopBar');
  const products = language === 'en' ? bankingProducts : esBankingProducts;

  const minHeight = 388;
  const menu = useRef(null);
  const title = useRef(null);
  const content = useRef(null);
  const personalRef = useRef(null);
  const businessRef = useRef(null);
  const openTween = useRef(null);
  const swapTween = useRef(null);
  const [personal, setPersonal] = useState(true);
  const [business, setBusiness] = useState(false);

  useEffect(() => {
    // Set default values

    gsap.set(menu.current, {
      height: Math.max(personalRef.current.offsetHeight + 100, minHeight),
    });
    gsap.set(menu.current, { autoAlpha: 0, y: -300 });
    gsap.set(content.current, { autoAlpha: 0 });

    // Build reveal animation
    openTween.current = new TimelineMax();
    openTween.current
      .delay(0.25)
      .add(gsap.set(personalRef.current, { autoAlpha: 1 }))
      .add(TweenMax.to(menu.current, 0.25, { autoAlpha: 1, y: 0 }))
      .add(TweenMax.to(content.current, 0.3, { autoAlpha: 1 }));

    openTween.current.play().reverse();

    return () => {
      openTween.current.kill();
    };
  }, []);

  useEffect(() => {
    openTween.current.reversed(!isOpen);
  }, [isOpen]);

  const businessClick = () => {
    if (swapTween.current) swapTween.current.kill();
    let targetHeight = Math.max(
      businessRef.current.offsetHeight + 100,
      minHeight
    );

    swapTween.current = new TimelineMax();
    swapTween.current
      .add(() => setPersonal(false))
      .add(() => setBusiness(true))
      .add(TweenMax.to(personalRef.current, 0.5, { autoAlpha: 0 }));

    if (menu.current.offsetHeight != targetHeight)
      swapTween.current.add(
        TweenMax.to(menu.current, 0.5, { height: targetHeight }),
        '-=0.15'
      );

    swapTween.current
      .add(TweenMax.to(businessRef.current, 0.5, { autoAlpha: 1 }), '-=0.25')
      .play();
  };
  const pbClick = () => {
    if (swapTween.current) swapTween.current.kill();
    let targetHeight = Math.max(
      personalRef.current.offsetHeight + 100,
      minHeight
    );

    swapTween.current = new TimelineMax();
    swapTween.current
      .add(() => setBusiness(false))
      .add(() => setPersonal(true))
      .add(TweenMax.to(businessRef.current, 0.5, { autoAlpha: 0 }));

    if (menu.current.offsetHeight != targetHeight)
      swapTween.current.add(
        TweenMax.to(menu.current, 0.5, { height: targetHeight }),
        '-=0.15'
      );

    swapTween.current
      .add(TweenMax.to(personalRef.current, 0.5, { autoAlpha: 1 }), '-=0.25')
      .play();
  };

  const mouseLeave = () => {
    pbClick();
    closeActiveMenu();
  };

  const hasAnyProducts = category => {
    return products.filter(x => x.category === category).length > 0;
  };

  return (
    <nav className="top-bar-menu banking" ref={menu} onMouseLeave={mouseLeave}>
      <div className="content-wrap" ref={content}>
        <div className="title-section" ref={title}>
          <Title>{t('banking')}</Title>
          <IconUnderlineButton
            to="/banking/personal"
            icon={<PersonalIcon />}
            label={t('personal')}
            active={personal}
            onMouseEnter={pbClick}
          />
          <IconUnderlineButton
            to="/banking/business"
            icon={<BusinessIcon />}
            label={t('business')}
            active={business}
            onMouseEnter={businessClick}
          />
        </div>
        <div className="menu-section">
          <div
            className={`menu personal-menu${personal ? ' active' : ''}`}
            ref={personalRef}
          >
            {categories
              .filter(category => {
                return category !== 'cards';
              })
              .map(category => (
                <div className={`category-menu personal-${category}`}>
                  <h6>{data.personal[category]}</h6>
                  <ul>
                    {products
                      .filter(product => {
                        return product.category === `personal-${category}`;
                      })
                      .map(product => (
                        <li key={product.slug}>
                          <Link to={`/banking/personal/${product.slug}`}>
                            {product.title}
                          </Link>
                        </li>
                      ))}
                    {category === 'checking' && (
                      <li key="personal-rewards">
                        <Link to={`/rewards`}>LSNB Rewards</Link>
                      </li>
                    )}
                  </ul>
                </div>
              ))}
          </div>
          <div
            className={`menu business-menu${business ? ' active' : ''}`}
            ref={businessRef}
          >
            {categories
              .filter(category => {
                return category !== 'cards';
              })
              .map(category => {
                return hasAnyProducts(`business-${category}`) ? (
                  <div className={`category-menu business-${category}`}>
                    <h6>{data.business[category]}</h6>
                    <ul>
                      {products
                        .filter(product => {
                          return product.category === `business-${category}`;
                        })
                        .map(product => (
                          <li key={product.slug}>
                            <Link to={`/banking/business/${product.slug}`}>
                              {product.title}
                            </Link>
                          </li>
                        ))}
                      {category === 'checking' && (
                        <li key="business-rewards">
                          <Link to={`/rewards`}>LSNB Rewards</Link>
                        </li>
                      )}
                    </ul>
                  </div>
                ) : (
                  <></>
                );
              })}
          </div>
        </div>
      </div>
    </nav>
  );
};

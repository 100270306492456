import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { LinkButton } from '../';
import { NavArrowIcon } from '../../icons';

import './style.scss';

export default ({ top = false }) => {
  const { language, languages, changeLanguage } = useI18next();

  return (
    <div className={`language-selector${top ? ' top-dropdown' : ''}`}>
      <LinkButton
        className="current-language-link"
        ariaLabel="Language Selector"
      >
        <span>{language}</span>
        <NavArrowIcon />
      </LinkButton>
      <ul className="available-languages-dropdown">
        {languages.map(lng => (
          <li key={lng}>
            <LinkButton
              className="language"
              onClick={e => {
                e.preventDefault();
                changeLanguage(lng);
              }}
              ariaLabel={lng}
            >
              <span>{lng}</span>
            </LinkButton>
          </li>
        ))}
      </ul>
    </div>
  );
};

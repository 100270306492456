import React, { useState } from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

import { IconUnderlineButton } from 'src/components/core/buttons';
import { BusinessIcon, PersonalIcon } from 'src/components/icons/navigation';
import { Link } from 'src/components/core/external-link';

import '../style.scss';

import { default as ComponentData } from '../../../../../../data/components/menus/insurance';
const insuranceProducts = require('../../../../../../data/content-types/insurance-products/en.json');
const esInsuranceProducts = require('../../../../../../data/content-types/insurance-products/es.json');

const InsuranceMenu = () => {
  const [active, setActive] = useState('personal');
  const handleUpdate = area => () => {
    setActive(area);
  };

  const { language } = useI18next();
  const data = ComponentData[language];
  const { t } = useTranslation('TopBar');
  const products = language === 'en' ? insuranceProducts : esInsuranceProducts;

  return (
    <div className="mobile-sub-menu">
      <div style={{ marginBottom: '1.5rem' }}>
        <Link to="/insurance">View Insurance Overview</Link>
      </div>

      <div className="selector">
        <IconUnderlineButton
          icon={<PersonalIcon />}
          label={t('personal')}
          active={active === 'personal'}
          onClick={handleUpdate('personal')}
        />
        <IconUnderlineButton
          icon={<BusinessIcon />}
          label={t('business')}
          active={active === 'business'}
          onClick={handleUpdate('business')}
        />
      </div>

      {active === 'personal' && (
        <>
          <div className={`category-menu`}>
            <h6>{data.personalSolutions}</h6>
            <ul>
              {products
                .filter(product => {
                  return product.category === 'personal';
                })
                .map(product => (
                  <li key={product.slug}>
                    <Link to={`/insurance/personal/${product.slug}`}>
                      {product.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </>
      )}

      {active === 'business' && (
        <>
          <div className={`category-menu`}>
            <h6>{data.businessSolutions}</h6>
            <ul>
              {products
                .filter(product => {
                  return product.category === 'business';
                })
                .map(product => (
                  <li key={product.slug}>
                    <Link to={`/insurance/business/${product.slug}`}>
                      {product.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default InsuranceMenu;

import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import { default as Logo } from '../logo';
import { Preface, TextBlock } from '../';

import './style.scss';

import HolidayGraphic from 'src/assets/images/christmas_graphic_cowboy_cash.jpg';

export default ({ showExtended, url, isOpen, setIsOpen }) => {
  const { language } = useI18next();
  const data = useStaticQuery(graphql`
    query {
      allRoadbumpJson {
        edges {
          node {
            language
            title
            subTitle
            body
            cancel
            continue
          }
        }
      }
    }
  `);

  const t = {
    en: data.allRoadbumpJson.edges.filter(edge => {
      return edge.node.language === 'en';
    })[0].node,
    es: data.allRoadbumpJson.edges.filter(edge => {
      return edge.node.language === 'es';
    })[0].node,
  }[language];

  const dismiss = evt => {
    evt.preventDefault();
    setIsOpen(false);
  };

  const accept = evt => {
    evt.preventDefault();
    window.location = url;
  };

  const Img = styled.img`
    width: 100%;
    margin-bottom: 1.5rem;
    height: 150px;
    object-fit: cover;
    @media only screen and (max-width: 1279px) {
      display: none;
    }
  `;

  const FlexWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  `;

  return (
    <div className={`holiday-roadbump-modal${isOpen ? ` open` : ``} extended`}>
      <div className="darkener" onClick={dismiss}></div>
      <div className="content">
        <div className="title-bar">
          <Logo />
        </div>

        <div className="body">
          <Img src={HolidayGraphic} alt="Happy Holidays" />

          <h5>Holiday Hours</h5>
          <div className="scroll-wrap">
            <FlexWrap>
              <TextBlock style={{ textAlign: 'left', marginBottom: '1.5rem' }}>
                <Preface>Rio Grande Valley</Preface>
                <span style={{ fontWeight: 'bold' }}>
                  Friday, December 24, 2021:
                </span>
                <br />
                Lobby and Motor Bank closes at 4:00 p.m.
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Saturday, December 25, 2021:
                </span>
                <br />
                Bank closed in observance of Christmas Day.
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Friday, December 31, 2021:
                </span>
                <br />
                Lobby and Motor Bank closes at 5:00 p.m.
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Saturday, January 1, 2022:
                </span>
                <br />
                Bank closed in observance of New Year's Day.
              </TextBlock>
              <TextBlock style={{ textAlign: 'left' }}>
                <Preface>San Antonio</Preface>
                <span style={{ fontWeight: 'bold' }}>
                  Friday, December 24, 2021:
                </span>
                <br />
                Lobby and Motor Bank closes at 4:00 p.m.
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Saturday, December 25, 2021:
                </span>
                <br />
                Bank closed in observance of Christmas Day.
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Friday, December 31, 2021:
                </span>
                <br />
                Lobby and Motor Bank closes at 4:30 p.m.
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Saturday, January 1, 2022:
                </span>
                <br />
                Bank closed in observance of New Year's Day.
              </TextBlock>
            </FlexWrap>
          </div>

          <div className="actions">
            <button className="button hollow-button" onClick={dismiss}>
              <span>{t.cancel}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

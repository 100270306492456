import React from "react"
import { ReactComponent as FacebookIcon } from "src/assets/svg/footer/facebook-icon.svg"
import "./style.scss"

export default () => {
  return (
    <div className="footer-facebook-icon">
      <FacebookIcon />
    </div>
  )
}

import React from "react" 
import { Link } from "gatsby-plugin-react-i18next"
import { NavLogo } from "src/components/icons"

export default () => (
  <div className="logo">
    <Link to="/" aria-label="Home">
      <NavLogo />
    </Link>
  </div>
)

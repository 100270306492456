import React, { useState, useRef, useEffect } from "react"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import gsap, { TimelineMax, TweenMax } from "gsap/all"

import { Title } from "src/components/core"
import { IconUnderlineButton } from "src/components/core/buttons"
import { BusinessIcon, PersonalIcon } from "src/components/icons/navigation"
// import { Link } from "gatsby"
import { Link } from "src/components/core/external-link"

import "./style.scss"
import "../style.scss"

const bankingProducts = require("../../../../../../data/content-types/banking-products/en.json")
const esBankingProducts = require("../../../../../../data/content-types/banking-products/en.json")
let categories = bankingProducts.map(product => product.category.replace("business-", "").replace("personal-", ""))
categories = [...new Set(categories)]


export default ({ isOpen }) => {
  const { language } = useI18next()
  const { t } = useTranslation("TopBar")
  const products = (language === "en") ? bankingProducts : esBankingProducts
  

  const menu = useRef(null)
  const title = useRef(null)
  const content = useRef(null)
  const personalRef = useRef(null)
  const businessRef = useRef(null)
  const openTween = useRef(null)
  const swapTween = useRef(null)
  const [personal, setPersonal] = useState(true)
  const [business, setBusiness] = useState(false)

  
  useEffect(() => {
    const title = content.current.querySelector('.title-section')
    const categoryMenus = content.current.querySelectorAll('.menu-section .active .category-menu')
    const categoryMenuItems = content.current.querySelectorAll('.menu-section .active h6, .menu-section .active li')
    
    // Set default values 
    gsap.set(menu.current, { autoAlpha: 0, y: -300 })
    gsap.set(title, { autoAlpha: 0 })
    gsap.set(gsap.utils.toArray(categoryMenus), { autoAlpha: 0 })
    gsap.set(gsap.utils.toArray(categoryMenuItems), { autoAlpha: 0, y: 15 })
    
    // Build reveal animation 
    openTween.current = new TimelineMax()
    openTween.current
      .add(gsap.set(personalRef.current, { autoAlpha: 1 }))
      .add(TweenMax.to(menu.current, 0.5, { autoAlpha: 1, y: 0 }))
      .add(TweenMax.to(title, 0.5, { autoAlpha: 1 }))
    
    categoryMenus.forEach(m => {
      let items = gsap.utils.toArray(m.querySelectorAll('h6, li'))
      let tl = new TimelineMax() 
      tl.add(TweenMax.to(m, 0.5, { autoAlpha: 1 }))
      tl.add(TweenMax.to(items, 0.5, { autoAlpha: 1, y: 0, stagger: 0.15 }), "-=0.4")
      openTween.current.add(tl, "-=0.8")
    })

    openTween.current.play().reverse()

   

    return () => {
      openTween.current.kill()
    }
  }, [])

  useEffect(() => {
    openTween.current.reversed(!isOpen)
  }, [isOpen])


  const businessClick = () => {
    if (swapTween.current) swapTween.current.kill() 
    swapTween.current = new TimelineMax()
    swapTween.current
      .add(() => setPersonal(false))
      .add(() => setBusiness(true))
      .add(TweenMax.to(personalRef.current, 0.5, { autoAlpha: 0 }))
      .add(TweenMax.to(businessRef.current, 0.5, { autoAlpha: 1 }), "-=0.25")
      .play()
  }
  const pbClick = () => {
    if (swapTween.current) swapTween.current.kill() 
    swapTween.current = new TimelineMax()
    swapTween.current
      .add(() => setBusiness(false))
      .add(() => setPersonal(true))
      .add(TweenMax.to(businessRef.current, 0.5, { autoAlpha: 0 }))
      .add(TweenMax.to(personalRef.current, 0.5, { autoAlpha: 1 }), "-=0.25")
      .play()
  }


  return (
    <nav className="top-bar-menu banking" ref={menu}>
      <div className="content-wrap" ref={content}>
        <div className="title-section" ref={title}>
          <Title>{t("banking")}</Title>
          <IconUnderlineButton icon={<PersonalIcon />} label={t("personal")} active={personal} onMouseEnter={pbClick} />
          <IconUnderlineButton icon={<BusinessIcon />} label={t("business")} active={business} onMouseEnter={businessClick} />
        </div>
        <div className="menu-section">
          <div className={`menu personal-menu${personal ? " active" : ""}`} ref={personalRef}>
            {categories.filter(category => {
              return (category !== "cards")
            }).map(category => (
              <div className={`category-menu personal-${category}`}>
                <h6>Personal {category}</h6>
                <ul>
                  {products.filter(product => {
                    return product.category === `personal-${category}`
                  }).map(product => (
                    <li key={product.slug}>
                      <Link to={`/banking/personal/${product.slug}`}>{product.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className={`menu business-menu${business ? " active" : ""}`} ref={businessRef}>
            {categories.filter(category => {
              return (category !== "cards")
            }).map(category => (
              <div className={`category-menu business-${category}`}>
                <h6>Business {category}</h6>
                <ul>
                  {products.filter(product => {
                    return product.category === `business-${category}`
                  }).map(product => (
                    <li key={product.slug}>
                      <Link to={`/banking/business/${product.slug}`}>{product.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </nav>
  )
}

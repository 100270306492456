import React, { useState } from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

import { IconUnderlineButton } from 'src/components/core/buttons';
import { BusinessIcon, PersonalIcon } from 'src/components/icons/navigation';
import { Link } from 'src/components/core/external-link';

import '../style.scss';

import { default as ComponentData } from '../../../../../../data/components/menus/banking';
const bankingProducts = require('../../../../../../data/content-types/banking-products/en.json');
const esBankingProducts = require('../../../../../../data/content-types/banking-products/es.json');
let categories = bankingProducts.map(product =>
  product.category.replace('business-', '').replace('personal-', '')
);
categories = [...new Set(categories)];

const BankingMenu = () => {
  const [active, setActive] = useState('personal');
  const handleUpdate = area => () => {
    setActive(area);
  };

  const { language } = useI18next();
  const data = ComponentData[language];
  const { t } = useTranslation('TopBar');
  const products = language === 'en' ? bankingProducts : esBankingProducts;

  return (
    <div className="mobile-sub-menu">
      <div style={{ marginBottom: '1.5rem' }}>
        <Link to="/banking">View Banking Overview</Link>
      </div>

      <div className="selector">
        <IconUnderlineButton
          icon={<PersonalIcon />}
          label={t('personal')}
          active={active === 'personal'}
          onClick={handleUpdate('personal')}
        />
        <IconUnderlineButton
          icon={<BusinessIcon />}
          label={t('business')}
          active={active === 'business'}
          onClick={handleUpdate('business')}
        />
      </div>

      {active === 'personal' && (
        <>
          {categories
            .filter(category => {
              return category !== 'cards';
            })
            .map(category => (
              <div className={`category-menu personal-${category}`}>
                <h6>{data.personal[category]}</h6>
                <ul>
                  {products
                    .filter(product => {
                      return product.category === `personal-${category}`;
                    })
                    .map(product => (
                      <li key={product.slug}>
                        <Link to={`/banking/personal/${product.slug}`}>
                          {product.title}
                        </Link>
                      </li>
                    ))}
                  {category === 'checking' && (
                    <li key="personal-rewards">
                      <Link to={`/rewards`}>LSNB Rewards</Link>
                    </li>
                  )}
                </ul>
              </div>
            ))}
        </>
      )}

      {active === 'business' && (
        <>
          {categories
            .filter(category => {
              return category !== 'cards';
            })
            .map(category => (
              <div className={`category-menu business-${category}`}>
                <h6>{data.business[category]}</h6>
                <ul>
                  {products
                    .filter(product => {
                      return product.category === `business-${category}`;
                    })
                    .map(product => (
                      <li key={product.slug}>
                        <Link to={`/banking/business/${product.slug}`}>
                          {product.title}
                        </Link>
                      </li>
                    ))}
                  {category === 'checking' && (
                    <li key="business-rewards">
                      <Link to={`/rewards`}>LSNB Rewards</Link>
                    </li>
                  )}
                </ul>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default BankingMenu;

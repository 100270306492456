import React, { useRef, useEffect } from "react"
import gsap from "gsap/all"
import { asArray } from "src/util"

import "./style.scss"

export default ({ children }) => {
  const wrapper = useRef(null)

  const handleMouseMove = (e) => {
    const cursor = wrapper.current.querySelector(".cursor")
    const shapes = asArray(wrapper.current.querySelectorAll(".shape"))
    const bounds = wrapper.current.getBoundingClientRect()
    const mouseX = (e.clientX - bounds.left),
          mouseY = (e.clientY - bounds.top)

    gsap.set(cursor, { x: mouseX, y: mouseY })
    gsap.to(shapes, { x: mouseX, y: mouseY, stagger: -0.1 })
  }

  useEffect(() => {
    const wrap = wrapper.current 
    wrap.addEventListener("mousemove", handleMouseMove)
    return () => wrap.removeEventListener("mousemove", handleMouseMove)
  }, [])

  return (
    <div className="shapes-wrapper" ref={wrapper}>
      <div className="cursor"></div>
      <div className="shapes">
        <div className="shape shape-1"></div>
        <div className="shape shape-2"></div>
        <div className="shape shape-3"></div>
        <div className="shape shape-4"></div>
      </div>
      <div className="content">{children}</div>
    </div>
  )
}

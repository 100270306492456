import React from "react"
import { ReactComponent as TwitterIcon } from "src/assets/svg/footer/twitter-icon.svg"
import "./style.scss"

export default () => {
  return (
    <div className="footer-twitter-icon">
      <TwitterIcon />
    </div>
  )
}

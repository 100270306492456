/* eslint-disable */
import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { LinkButton } from "src/components/core"
import { RightChevronIcon, XIcon } from "src/components/icons"
import { Link } from "src/components/core/external-link"

import "./style.scss"

export default ({ visible, setVisible }) => {
  const { t } = useTranslation("LoginModal")

  return (
    <div className={`sign-in-modal${visible ? " open" : ""}`} onMouseLeave={() => setVisible(false) } role="dialog">
      <div className="mobile-close-button" style={{
        top: "1rem",
        right: "1rem",
        zIndex: 5,
        position: "absolute"
      }}>
        <LinkButton onClick={() => setVisible(false)} ariaLabel="Close">
          <XIcon />
        </LinkButton>
      </div>

      <div className="content">
        <h4>{t("view_accounts")}</h4>
        <hr />

        <form action="https://www.netteller.com/login2008/Authentication/Views/Login.aspx?returnUrl=%2flonestarnationalbank" method="POST">
          <label>{t("user_id")}</label>
          <div style={{ position: "relative" }}>
            <input name="id" type="text" autcomplete="off" placeholder={t("enter_user_id")} aria-label="User Id"/>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              right: 0,
              zIndex: 5,
              width: 25,
              height: "100%",
              position: "absolute"
            }}>
              <RightChevronIcon />
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <button className="button">{t("login")}</button>
          </div>
        </form>

        <p>
          {t("need_access")}
          <Link to={`https://www.netteller.com/login2008/Authentication/Views/OnlineEnrollmentAgreement.aspx?returnUrl=%2flonestarnationalbank%2f`} target="_blank" rel="noreferrer">
            <span>{t("sign_up")}</span>
          </Link>
          <br />
          <Link to={`https://www.netteller.com/login2008/Authentication/Views/LoginPasswordSelfReset.aspx?returnUrl=%2flonestarnationalbank`} style={{ marginTop: 8 }}>
            <span>{t("forgot_password")}</span>
          </Link>
        </p>
      </div>
    </div>
  )
}

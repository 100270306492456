import React from 'react';
// import { Link } from "gatsby-plugin-react-i18next"
import { Link } from 'src/components/core/external-link';
import './style.scss';

export default ({
  to,
  additionalClasses,
  tabIndex,
  children,
  target,
  openInNewWindow,
  trackCategory,
  trackLabel,
  trackEvent,
  trackValue,
  showExtended,
}) => {
  const handleClick = e => {
    var targetEl = e.target;
    var inkEl = targetEl.querySelector('.ink');

    if (inkEl) {
      inkEl.classList.remove('animate');
    } else {
      inkEl = document.createElement('span');
      inkEl.classList.add('ink');
      inkEl.style.width = inkEl.style.height =
        Math.max(targetEl.offsetWidth, targetEl.offsetHeight) + 'px';
      targetEl.appendChild(inkEl);
    }

    inkEl.style.left = e.nativeEvent.offsetX - inkEl.offsetWidth / 2 + 'px';
    inkEl.style.top = e.nativeEvent.offsetY - inkEl.offsetHeight / 2 + 'px';
    inkEl.classList.add('animate');
  };

  return (
    <Link
      to={to}
      showExtended={showExtended}
      className={`button ${additionalClasses ? additionalClasses : undefined}`}
      target={target}
      tabIndex={tabIndex}
      target={openInNewWindow ? '_blank' : undefined}
      onClick={handleClick}
      {...{ trackCategory, trackLabel, trackEvent, trackValue }}
    >
      {children}
    </Link>
  );
};

import React from "react"
import RightArrowImg from "src/assets/images/home/right-arrow-bl.png"
import "./style.scss"

export default () => {
  
  return (
    <div className="home-chevron-icon">
      <img src={RightArrowImg} alt="Right Arrow" />
    </div>
  )
}
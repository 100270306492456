import React from "react"

const AnimationContext = React.createContext({
  showPreloader: false,
  animDelay: 0.5,
  url: undefined,
  isOpen: false,
  setIsOpen: () => {},
  setUrl: () => {}
})

export default AnimationContext

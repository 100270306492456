import React from "react"

import { ReactComponent as RightArrow } from "src/assets/svg/right-arrow.svg"

import "./style.scss"

export default () => (
  <div className="icon-right-arrow">
    <RightArrow />
  </div>
)

import React from "react"
import { ReactComponent as RightArrow } from "src/assets/svg/slider/right-icon.svg"

import "./style.scss"

export default () => (
  <div className="right-circle-arrow-icon">
    <RightArrow />
  </div>
)

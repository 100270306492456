import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import { AnimationContext } from 'src/context';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import * as Url from 'url-parse';

const localUrls = [
  `http://localhost:8000`,
  'http://localhost:9000',
  `http://lsnb.ctbstaging.live`,
  `https://lsnb.ctbstaging.live`,
  `https://www.lonestarnationalbank.com`,
  `https://lonestarnationalbank.com`,
];

const present = val => {
  return val !== undefined && val !== null && val !== '';
};

const isExternal = href => {
  const url = new Url(href);
  if (url.origin === null) return false;

  return localUrls.indexOf(url.origin) === -1;

  // const origin = window.location.origin
  // return (url.origin !== origin)
};

const handleEventTracking = ({
  trackLabel,
  trackCategory,
  trackEvent,
  trackValue,
}) => {
  if (present(trackCategory) && present(trackEvent)) {
    const data = {
      category: trackCategory,
      action: trackEvent,
      label: trackLabel,
      value: trackValue,
    };
    trackCustomEvent(data);
    console.log('Tracking custom event', data);
  }
};

const ExternalLink = props => {
  return (
    <AnimationContext.Consumer>
      {({ setIsOpen, setUrl, setShowExtended }) => (
        <a
          {...props}
          href={props.to}
          onClick={e => {
            e.preventDefault();

            if (props.onClick) props.onClick(e);

            const { trackCategory, trackLabel, trackEvent, trackValue } = props;
            handleEventTracking({
              trackCategory,
              trackLabel,
              trackEvent,
              trackValue,
            });

            setShowExtended(props.showExtended === true);
            setUrl(e.currentTarget.href);
            setIsOpen(true);
          }}
        >
          {props.children}
        </a>
      )}
    </AnimationContext.Consumer>
  );
};

const ComplexLink = props => {
  return isExternal(props.to) ? (
    <ExternalLink {...props} />
  ) : (
    <Link
      {...props}
      onClick={() => {
        const { trackCategory, trackLabel, trackEvent, trackValue } = props;
        handleEventTracking({
          trackCategory,
          trackLabel,
          trackEvent,
          trackValue,
        });
      }}
    />
  );
};

export { ComplexLink as Link };

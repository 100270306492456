import React, { useState, useEffect } from "react" 

export default ({ src, alt, fallback }) => {

  const [imgSrc, setImgSrc] = useState(src)

  useEffect(() => {
    const img = new Image()
    img.onload = () => setImgSrc(src)
    img.onerror = () => setImgSrc(fallback)
    img.src = src 
  }, [src, fallback])
  

  return <img src={imgSrc} alt={alt} />
}
import React from "react"
import { ReactComponent as Logo } from "src/assets/svg/footer/lone-star-logo.svg"
import "./style.scss"


export default () => (
  <div className="footer-logo-icon">
    <Logo />
  </div>
)

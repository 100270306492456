import React, { useEffect, useRef } from 'react';
import gsap, { TimelineMax, TweenMax } from 'gsap/all';
import { Accordion, AccordionItem } from 'react-sanfona';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { RoundedImage } from 'src/components/core';
import { asArray } from 'src/util';

import './style.scss';

const globalTranslations = {
  en: require('../../../../locales/en/global.json'),
  es: require('../../../../locales/es/global.json'),
};

export default ({ sectionId, title, subTitle, content, image, data }) => {
  const { language } = useI18next();
  const t = globalTranslations[language];
  const elem = useRef(null);
  const revealTween = useRef(null);

  useEffect(() => {
    const g1 = elem.current.querySelector('.content-wrap');
    const g2 = asArray(
      elem.current.querySelectorAll(
        '.accordion .title h5, .accordion .title h3, .accordion .title p'
      )
    );
    const g3 = asArray(elem.current.querySelectorAll('.react-sanfona-item'));
    const g4 = asArray(
      elem.current.querySelectorAll('.action-bar, .video-container')
    );

    gsap.set(g1, { autoAlpha: 0 });
    gsap.set(g2, { autoAlpha: 0, y: 20 });
    gsap.set(g3, { autoAlpha: 0, x: -20 });
    gsap.set(g4, { autoAlpha: 0, x: 20 });

    revealTween.current = new TimelineMax({
      scrollTrigger: elem.current,
      start: 'top 80%',
    });

    revealTween.current
      .add(TweenMax.to(g1, 0.5, { autoAlpha: 1 }))
      .add(
        TweenMax.to(g2, 0.5, { autoAlpha: 1, y: 0, stagger: 0.15 }),
        '-=0.25'
      )
      .add(TweenMax.to(g3, 0.5, { autoAlpha: 1, x: 0, stagger: 0.15 }), '-=0.4')
      .add(
        TweenMax.to(g4, 0.5, { autoAlpha: 1, x: 0, stagger: 0.15 }),
        '-=0.5'
      );

    return () => revealTween.current.kill();
  });

  return (
    <section id={sectionId} className="product-accordion" ref={elem}>
      <div className="content-wrap">
        <div className="accordion">
          <div className="title">
            <h5>{subTitle}</h5>
            <h3>{title}</h3>
            {content !== undefined && content !== null && content !== '' && (
              <p>{content}</p>
            )}
          </div>

          <Accordion atomic={true}>
            {data.map((product, index) => (
              <AccordionItem title={product.title} expanded={index === 0}>
                {product.subTitle.indexOf('<p>') !== -1 ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: product.subTitle }}
                  ></div>
                ) : (
                  <p>{product.subTitle}</p>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="imagery">
          <div className="action-bar">
            <div className="title">
              <h5>{subTitle}</h5>
              <h3>{title}</h3>
            </div>
            {/* <button className="button">{t.compareAccounts}</button> */}
          </div>
          <div className="video-container">
            <RoundedImage src={image} alt={title} />
          </div>
        </div>
      </div>
    </section>
  );
};

import gsap from 'gsap/all';

export const toRem = px => {
  return px / 16.0;
};

export const toPx = rem => {
  return rem * 16.0;
};

export const asArray = elements => {
  return gsap.utils.toArray(elements);
};

export { default as getScroll } from './getScroll';

export const Config = {
  googlePlay:
    'https://play.google.com/store/apps/details?id=com.jackhenry.LoneStarNational',
  iTunes:
    'https://itunes.apple.com/us/app/lsnb-mobile-banking/id417253880?mt=8',
};

export const getTotalPages = (itemCount, itemsPerPage) => {
  return Math.ceil(itemCount / itemsPerPage);
};

export const getFirstSentance = text => {
  let tmp = text.replace('<br/><br/>', ' ').replace(/<[^>]*>?/gm, '');
  let stop = tmp.indexOf('.');
  tmp = tmp.substring(0, stop + 1);
  if (tmp.length > 135) {
    tmp = tmp.substring(0, 135).trim() + '...';
  }
  return tmp;
};

export const notNull = item => {
  return item !== undefined && item !== null;
};

export const isBlank = item => {
  return item === undefined || item === null || item === '';
};

export const isPresent = item => {
  return !isBlank(item);
};

export const isEmpty = item => {
  return !isPresent(item);
};

export const newlineToBr = item => {
  return item.replace(/(\r\n|\n|\r)/gm, '<br />');
};

export const formatMoney = item => {
  let number = parseFloat(item.trim());
  return `$${number
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const orDefault = (item, item2) => {
  return isBlank(item) ? item2 : item;
};

export const anyPresent = items => {
  return (
    items.filter(x => {
      return !isBlank(x);
    }).length > 0
  );
};

export { default as geoLocate } from './geoLocate';

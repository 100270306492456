import React from "react"
import { ReactComponent as X } from "src/assets/svg/x.svg"

import "./style.scss"

export default () => (
  <div className="x-icon">
    <X />
  </div>
)

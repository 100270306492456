import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { Link } from 'src/components/core/external-link';

import '../style.scss';

import { default as ComponentData } from '../../../../../../data/components/menus/digital-banking';
const dbProducts = require('../../../../../../data/content-types/digital-banking-products/en.json');
const esDbProducts = require('../../../../../../data/content-types/digital-banking-products/es.json');

const whitelist = [
  'person-to-person-transfers',
  'e-statements',
  'bank-to-bank-transfers',
  'atm-services',
  'bill-pay',
  'sms-banking',
  'card-manager',
  'contactless-transactions',
];

const DigitalBankingMenu = () => {
  const { language } = useI18next();
  const data = ComponentData[language];
  const products = language === 'en' ? dbProducts : esDbProducts;

  return (
    <div className="mobile-sub-menu">
      <div className="category-menu">
        <h6>{data.menuTitle}</h6>
        <ul>
          <li>
            <Link to="/banking/digital">Digital Banking Overview</Link>
          </li>
          {products
            .filter(x => {
              return whitelist.indexOf(x.slug) !== -1;
            })
            .map(product => (
              <li key={product.slug}>
                <Link to={`/digital-banking/${product.slug}`}>
                  {product.title}
                </Link>
              </li>
            ))}
          <li key="zelle">
            <Link to={`/digital-banking/zelle`}>Zelle®</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DigitalBankingMenu;

import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useStaticQuery } from 'gatsby';

import { default as Logo } from '../logo';
import { Preface, TextBlock } from '../';

import './style.scss';

export default ({ showExtended, url, isOpen, setIsOpen }) => {
  const { language } = useI18next();
  const data = useStaticQuery(graphql`
    query {
      allRoadbumpJson {
        edges {
          node {
            language
            title
            subTitle
            body
            cancel
            continue
          }
        }
      }
    }
  `);

  const t = {
    en: data.allRoadbumpJson.edges.filter(edge => {
      return edge.node.language === 'en';
    })[0].node,
    es: data.allRoadbumpJson.edges.filter(edge => {
      return edge.node.language === 'es';
    })[0].node,
  }[language];

  const dismiss = evt => {
    evt.preventDefault();
    setIsOpen(false);
  };

  const accept = evt => {
    evt.preventDefault();
    window.location = url;
  };

  return (
    <div
      className={`roadbump-modal${isOpen ? ` open` : ``}${
        showExtended ? ` extended` : ``
      }`}
    >
      <div className="darkener" onClick={dismiss}></div>
      <div className="content">
        <div className="title-bar">
          <Logo />
        </div>

        <div className="body">
          <h5>{t.subTitle}</h5>
          <Preface>{t.title}</Preface>
          <div className="scroll-wrap">
            <TextBlock>{t.body}</TextBlock>
          </div>

          <div className="actions">
            <button className="button hollow-button" onClick={dismiss}>
              <span>{t.cancel}</span>
            </button>
            <button className="button" onClick={accept}>
              <span>{t.continue}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
